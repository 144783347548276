import * as actionTypes from "./actionsTypes";
import { isEmty, arrayContainsAnotherArray } from "../../ulitity/ulitity";
import newId from "../../ulitity/idGenerator";
import { confirmAlert } from "react-confirm-alert";
import firebase from "firebase";
import http from "../../lib/http-service";
import { db } from "../../firebase-config";

export const normalizeSurveyForSubmit = (survey) => {
  let questions = {};
  if (survey !== null) {
    survey.questions.forEach((question) => {
      questions[question._id] = question;
    });
    const questionRequiredGrouped = [];
    if (survey.grouped_questions?.length > 0) {
      survey.grouped_questions.forEach((group) => {
        group.questions.forEach((question) => {
          if (questions[question].isRequired)
            questionRequiredGrouped.push(questions[question]._id);
        });
      });
    }
    let question_required = survey.questions
      .filter((question) => question.isRequired === true)
      .map((question) => question._id);
    let question_order = survey.questions.map((question) => question._id);
    if (survey?.type === actionTypes.COMPARATIVE) {
      return {
        title: survey.title,
        subTitle: survey.subTitle,
        openTill: survey.openTill,
        questions: questions,
        allQuestionsObject: questions,
        question_order: question_order,
        question_required: questionRequiredGrouped.length
          ? questionRequiredGrouped
          : question_required,
        current_question_id: "",
        creatorDate: survey.creatorDate,
        lastModified: survey.lastModified,
        submitting: survey.submitting,
        type: survey.type || "",
        grouped_questions: survey.grouped_questions || [],
        ungrouped_questions: survey.ungrouped_questions || [],
        surveyee_count: survey.surveyee_count || 0,
        surveyee_list: survey.surveyee_list || [],
        ratingIndex: survey.ratingIndex || "",
        ratingIndexAsFootnote: survey.ratingIndexAsFootnote || false,
      };
    }
    return {
      title: survey.title,
      subTitle: survey.subTitle,
      questions: questions,
      allQuestionsObject: questions,
      openTill: survey.openTill,
      question_order: question_order,
      question_required: questionRequiredGrouped.length
        ? questionRequiredGrouped
        : question_required,
      current_question_id: "",
      creatorDate: survey.creatorDate,
      lastModified: survey.lastModified,
      type: actionTypes.REGULAR,
      submitting: survey.submitting,
      grouped_questions: survey.grouped_questions || [],
      ungrouped_questions: survey.ungrouped_questions || [...question_order],
    };
  }
};

export const fetchUserSurveyStart = () => {
  return {
    type: actionTypes.FETCH_USER_SURVEY_START,
  };
};

export const fetchUserSurveySuccess = (survey) => {
  return {
    type: actionTypes.FETCH_USER_SURVEY_SUCCESS,
    payload: normalizeSurveyForSubmit(survey),
  };
};

export const fetchUserSurveyFail = (err) => {
  return {
    type: actionTypes.FETCH_USER_SURVEY_FAIL,
    error: err,
  };
};

export const fetchUserSurvey = (surveyId, compId) => {
  return (dispatch) => {
    dispatch(fetchUserSurveyStart());

    db.collection(`surveys/${compId}/surveysList`)
      .doc(String(surveyId))
      .onSnapshot(
        (doc) => {
          if (!doc.exists) return;

          const docData = { ...doc.data() };
          dispatch(fetchUserSurveySuccess(docData?.content));
        },
        (err) => console.log(err)
      );
  };
};

export const submitSurveyStart = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_START,
  };
};

export const submitSurveySuccess = (survey) => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SUCCESS,
    survey: survey,
  };
};

export const submitSurveyFail = (err) => {
  return {
    type: actionTypes.SUBMIT_SURVEY_FAIL,
    error: err,
  };
};

export const submitSurveyRequired = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_REQUIRED,
  };
};
export const assembleRegularSubmitdata = (data) => {
  let resultant = {};
  Object.keys(data).forEach((questId) => {
    if (data[questId].hasOwnProperty("")) {
      resultant[questId] = data[questId][""];
    } else if (typeof data[questId] === "string") {
      resultant[questId] = data[questId];
    } else {
      resultant[questId] = {};
      Object.keys(data[questId]).forEach((optionId) => {
        if (typeof data[questId][optionId] === "object") {
          resultant[questId][optionId] = data[questId][optionId][""];
        } else {
          resultant[questId][optionId] = data[questId][optionId];
        }
      });
    }
  });

  return resultant;
};
export const mergeDraftValues = (lastSaved, values, type = "") => {
  let updatedValuesObj = { ...values };
  let finalResults = { ...lastSaved };
  if (type === actionTypes.COMPARATIVE) {
    Object.keys(finalResults).forEach((id) => {
      if (updatedValuesObj[id]) {
        if (Array.isArray(finalResults[id])) {
          for (
            let i = 0;
            i < Math.max(updatedValuesObj[id].length, finalResults[id].length);
            i++
          ) {
            if (updatedValuesObj[id][i] || i in updatedValuesObj[id]) {
              finalResults[id][i] = updatedValuesObj[id][i];
            }
          }
        } else if (!Array.isArray(finalResults[id])) {
          Object.keys(finalResults[id]).forEach((optionKey) => {
            if (updatedValuesObj[id][optionKey]) {
              for (
                let i = 0;
                i <
                Math.max(
                  updatedValuesObj[id][optionKey].length,
                  finalResults[id][optionKey].length
                );
                i++
              ) {
                if (
                  updatedValuesObj[id][optionKey][i] ||
                  i in updatedValuesObj[id][optionKey]
                ) {
                  finalResults[id][optionKey][i] =
                    updatedValuesObj[id][optionKey][i];
                }
              }
              delete updatedValuesObj[id][optionKey];
            }
          });
          let finalCheckboxObj = {
            ...finalResults[id],
            ...updatedValuesObj[id],
          };
          finalResults[id] = { ...finalCheckboxObj };
        }
        delete updatedValuesObj[id];
      }
    });
  }
  if (type !== actionTypes.COMPARATIVE) {
    Object.keys(finalResults).forEach((id) => {
      if (typeof finalResults[id] === "object" && updatedValuesObj[id]) {
        Object.keys(finalResults[id]).forEach((optionId) => {
          if (optionId in updatedValuesObj[id]) {
            finalResults[id][optionId] = updatedValuesObj[id][optionId];
          }
          delete updatedValuesObj[id][optionId];
        });
        let finalCheckboxObj = {
          ...finalResults[id],
          ...updatedValuesObj[id],
        };
        finalResults[id] = { ...finalCheckboxObj };
        delete updatedValuesObj[id];
      }
    });
  }
  return { ...finalResults, ...updatedValuesObj };
};
export const assembleComparativeSubmitData = (data) => {
  let resultant = {};
  Object.keys(data).forEach((questId) => {
    if (!Array.isArray(data[questId])) {
      resultant[questId] = {};
      Object.keys(data[questId]).forEach((optionId) => {
        resultant[questId][optionId] = [];
        for (let i = 0; i < data[questId][optionId].length; i++) {
          if (data[questId][optionId][i] || data[questId][optionId][i] === "") {
            resultant[questId][optionId].push(data[questId][optionId][i]);
          } else if (!data[questId][optionId][i]) {
            resultant[questId][optionId].push(null);
          }
        }
      });
    } else if (Array.isArray(data[questId])) {
      resultant[questId] = [];
      for (let i = 0; i < data[questId].length; i++) {
        if (data[questId][i] || data[questId][i] === "") {
          resultant[questId].push(data[questId][i]);
        } else if (!data[questId][i]) {
          resultant[questId].push(null);
        }
      }
    }
  });
  return resultant;
};

export const submitSurvey = (
  data,
  surveyId,
  question_required,
  type,
  surveyee_list
) => {
  return (dispatch) => {
    let dataArray = [];
    let assembledData = {};

    if (type !== actionTypes.COMPARATIVE) {
      assembledData = { ...assembleRegularSubmitdata(data) };
    } else {
      assembledData = { ...data };
    }

    for (let key in assembledData) {
      dataArray.push({
        ...assembledData[key],
        _id: key,
      });
    }
    let dataArrayId = dataArray.map((data) => data._id);
    if (
      arrayContainsAnotherArray(
        question_required,
        dataArrayId,
        type,
        surveyee_list,
        assembledData
      ) === false
    ) {
      dispatch(submitSurveyRequired());
    } else if (
      arrayContainsAnotherArray(
        question_required,
        dataArrayId,
        type,
        surveyee_list,
        assembledData
      ) === true
    ) {
      dispatch(submitSurveyStart());
      let result = {};
      const uid = firebase.auth().currentUser.uid;
      let respondent = {};
      const id = newId();
      result = {
        id: id,
        result: { ...assembledData },
        surveyId: surveyId,
        submitDate: new Date().toString(),
      };
      respondent = {
        uid: uid,
      };
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            http
              .post(
                "/changeStatus?idval=" +
                  window.location.href.split("/")[4] +
                  "&hash=" +
                  window.location.href.split("/")[3],
                result
              )
              .then((res) => {
                const { data } = res;
                if (data.response === "Successful") {
                  dispatch(submitSurveySuccess());
                }
              })
              .catch((err) => {
                dispatch(submitSurveyFail(err));
              });
          });
        }
      });
    }
  };
};
